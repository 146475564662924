export const meta: BlockMeta = {
  editMode: "both",
  title: "Feature carousel",
  description: "",
  keywords: ["carousel"],
  // @ts-ignore
  icon: "slides",
  cache: false,
  tags: ["root"],
  conditions: {
    multiple: true,
  },
}

import { defineBlock, EditableText } from "eddev/blocks"
import { useAdjustedHeight } from "@hooks/useAdjustedHeight"
import { GridLines } from "@components/atoms/GridLines"
import { Swiper, SwiperSlide } from "@components/atoms/Swiper"
import { Eyebrow } from "@components/atoms/Eyebrow"
import { ResponsiveImage } from "@components/atoms/ResponsiveImage"
import { Link } from "eddev/routing"
import { Button } from "@components/atoms/Button"
import { useState } from "react"
import { ResponsiveImageFragment } from "../../types.graphql"

export default defineBlock("content/feature-carousel", (props) => {
  const { addRef } = useAdjustedHeight()

  return (
    <div ref={(el) => (props.gridlines ? el && addRef(el) : undefined)} className={`subtheme-${props.scheme} relative`}>
      {props.gridlines && <GridLines />}
      <div className="w-full h-full flex justify-center items-start bg-bg overflow-hidden">
        <div className="grid-auto relative pb-10 lg:max-w-[1280px] xl:max-w-[1440px]">
          <div className="col-span-12 px-5 h-[80px] flex items-center">
            <EditableText className="type-title-s text-textAlt w-full" store="title" placeholder="Enter title..." />
          </div>

          {props?.cards && (
            <div
              ref={(el) => (props.gridlines ? el && addRef(el) : undefined)}
              className="col-span-12 flex items-stretch [&>*]:w-full"
            >
              <Swiper
                a11y={{
                  enabled: true,
                }}
                keyboard={{
                  enabled: true,
                }}
                mousewheel={{
                  enabled: true,
                  forceToAxis: true,
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  900: {
                    slidesPerView: 3,
                  },
                }}
                speed={600}
                injectStyles={[
                  `
                    .swiper {
                      overflow: visible;
                    }
                  `,
                ]}
              >
                {props.cards.map((card, i) => (
                  <SwiperSlide key={i} style={{ height: "auto", position: "relative" }}>
                    {card?.cardType === "link" && (
                      <Link
                        href={card?.link?.url ?? undefined}
                        target={card?.link?.target ? "_blank" : undefined}
                        className="flex flex-col justify-between md:justify-start w-full h-full bg-ruler md-inset-bg inset-hover group"
                      >
                        <div className="w-full block pb-6">{card?.image && <ResponsiveImage {...card.image} />}</div>
                        <div
                          className={`flex justify-between items-start flex-grow transition-all in-out-sumday duration-300 group-hover:-translate-y-2 px-8 gap-5 ${props.gridlines ? `pb-8 md:pb-0` : `pb-8 border-b border-ruler`}`}
                        >
                          <div className="w-full flex flex-col">
                            {card?.useEyebrow && (
                              <>
                                <div className="hidden md:block">
                                  <Eyebrow size="m" style="primary-clear" icon={card?.eyebrowIcon ?? undefined}>
                                    {card?.eyebrowText ?? ""}
                                  </Eyebrow>
                                </div>
                                <div className="md:hidden">
                                  <Eyebrow size="l" style="primary-clear" icon={card?.eyebrowIcon ?? undefined}>
                                    {card?.eyebrowText ?? ""}
                                  </Eyebrow>
                                </div>
                              </>
                            )}

                            <h3 className="type-title-s text-text pt-2">{card?.title}</h3>
                          </div>
                          <Button
                            iconLeft="ARROW_RIGHT"
                            size="m"
                            style="primary"
                            className="flex md:opacity-0 self-end group-hover:opacity-100 -translate-y-3/4 group-hover:-translate-y-full"
                          />
                        </div>
                      </Link>
                    )}

                    {card?.cardType === "overlay" && (
                      <OverLayCard card={card} gridlines={props.gridlines ? true : false} />
                    )}

                    {card?.cardType === "static" && (
                      <div className="flex flex-col justify-between md:justify-start w-full h-full bg-ruler md-inset-bg">
                        <div className="w-full block pb-6">{card?.image && <ResponsiveImage {...card.image} />}</div>
                        <div
                          className={`flex justify-between items-end px-8 pb-8 md:pb-0 ${props.gridlines ? `` : `pb-8 border-b border-ruler`}`}
                        >
                          <div className="w-full flex flex-col">
                            {card?.useEyebrow && (
                              <>
                                <div className="hidden md:block">
                                  <Eyebrow size="m" style="primary-clear" icon={card?.eyebrowIcon ?? undefined}>
                                    {card?.eyebrowText ?? ""}
                                  </Eyebrow>
                                </div>
                                <div className="md:hidden">
                                  <Eyebrow size="l" style="primary-clear" icon={card?.eyebrowIcon ?? undefined}>
                                    {card?.eyebrowText ?? ""}
                                  </Eyebrow>
                                </div>
                              </>
                            )}

                            <h3 className="type-title-s text-text pt-2">{card?.title}</h3>
                          </div>
                        </div>
                      </div>
                    )}
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        </div>
      </div>
    </div>
  )
})

const OverLayCard = ({
  card,
  gridlines,
}: {
  card: {
    cardType: string | null
    useEyebrow: boolean | null
    eyebrowText: string | null
    eyebrowIcon: string | null
    title: string | null
    overlayContent: string | null
    image: ResponsiveImageFragment | null
    link: {
      url: string | null
      target: string | null
      title: string | null
    } | null
  } | null
  gridlines?: boolean
}) => {
  const [overlayActive, setOverlayActive] = useState<boolean>(false)

  return (
    <div
      className="flex w-full h-full overflow-hidden relative cursor-pointer"
      onClick={() => setOverlayActive(!overlayActive)}
    >
      <div className="flex flex-col justify-between md:justify-start w-full h-full bg-ruler md-inset-bg group">
        <div className="w-full block pb-6">{card?.image && <ResponsiveImage {...card.image} />}</div>
        <div
          className={`flex justify-between items-end transition-all in-out-sumday duration-300 group-hover:-translate-y-2 px-8 gap-5 ${gridlines ? `pb-8 md:pb-0` : `pb-8 border-b border-ruler`}`}
        >
          <div className="w-full flex flex-col">
            {card?.useEyebrow && (
              <>
                <div className="hidden md:block">
                  <Eyebrow size="m" style="primary-clear" icon={card?.eyebrowIcon ?? undefined}>
                    {card?.eyebrowText ?? ""}
                  </Eyebrow>
                </div>
                <div className="md:hidden">
                  <Eyebrow size="l" style="primary-clear" icon={card?.eyebrowIcon ?? undefined}>
                    {card?.eyebrowText ?? ""}
                  </Eyebrow>
                </div>
              </>
            )}

            <h3 className="type-title-s text-text pt-2">{card?.title}</h3>
          </div>
          <Button
            iconLeft="ARROW_UP"
            size="m"
            style="primary"
            className="flex md:opacity-0 self-end group-hover:opacity-100 -translate-y-3/4 group-hover:-translate-y-full"
          />
        </div>
      </div>
      <div
        className={`absolute z-10 w-full h-full bg-ruler top-0 left-0 p-8 ${overlayActive ? `translate-y-0` : `translate-y-[100%]`} transition-all in-out-sumday duration-300`}
      >
        <p className="text-text type-body-l" dangerouslySetInnerHTML={{ __html: card?.overlayContent ?? " " }} />

        <div className="flex justify-end items-end pt-10">
          <Button iconLeft="ARROW_DOWN" size="m" style="primary" className="flex self-end opacity-100" />
        </div>
      </div>
    </div>
  )
}
